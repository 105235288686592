import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

const useAuthStore = create()(
    immer((set) => ({
        access: null,
        setAccess(access) {
            set((state) => {
                state.access = access
            })
        },
    }))
)

if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('auth', useAuthStore)
}

export default useAuthStore
