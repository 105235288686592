// export const STATIC_HOST = "https://bewebstudio.digitalauto.tech/";
export const STATIC_HOST = 'https://be-studio.digital.auto/'
// export const STATIC_HOST = 'http://localhost:9001/'
// export const STATIC_HOST = "https://mediadev.digitalauto.tech/";
// export const STATIC_HOST = "https://studio-t96u.onrender.com/";

export const STATIC_USER_HOST = 'https://user-mgmt.digital.auto/api/v1/'
// export const STATIC_USER_HOST = 'http://localhost:8082/api/v1/'

export const STATIC_UPLOAD_URL = 'https://upload.digital.auto/upload/be-studio/'
export const STORE_BE = 'https://store-be.digital.auto/'
