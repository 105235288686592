import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import SuccessIcon from '../../utils/images/success_icon.png'

NavigationModal.propTypes = {}
const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    width: '33rem',
    height: '20rem',
    bgcolor: 'background.paper',
    boxShadow:
        'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px',
    borderRadius: '10px',
}

function NavigationModal({ isOpenNavigateModal, handleClose }) {
    const [onEnter, setOnEnter] = React.useState(false)
    const handleNavigate = () => {
        window.location.href = `https://store.digital.auto/package/${isOpenNavigateModal.packageId}`
    }

    return (
        <div>
            <Modal
                open={isOpenNavigateModal.status}
                aria-labelledby='modall-modal-title'
                aria-describedby='modall-modal-description'
                sx={{
                    transitionProperty: 'all',
                    transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
                    transitionDuration: '150ms',
                    marginTop: '-4px',
                }}
            >
                <Box sx={style}>
                    {/* Dialog content */}
                    <div className='font-bold text-gray-800 text-lg !border-b w-full h-[15rem] flex flex-col justify-center items-center p-7 text-center'>
                        <div className='w-[8rem] m-auto'>
                            <img src={SuccessIcon} />
                        </div>
                        <div>Deploy Successfully</div>
                    </div>

                    {/* Dialog action */}
                    <div className='pr-5 pt-4 flex justify-end gap-2'>
                        <Button
                            onClick={() => {
                                handleClose()
                            }}
                            sx={{
                                bgcolor: 'white',
                                color: 'rgb(55 65 81)',
                                padding: '12px 16px',
                                border: '1px solid #80808033',
                                '&:hover': {
                                    backgroundColor: 'rgb(249 250 251)',
                                },
                                borderRadius: '0.375rem',
                            }}
                        >
                            Close
                        </Button>

                        <Button
                            onClick={handleNavigate}
                            sx={{
                                padding: '12px 16px',
                                backgroundColor: onEnter === true ? 'rgb(37 99 235)' : 'rgb(59 130 246)',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'rgb(37 99 235)',
                                },
                                '&:disabled': {
                                    backgroundColor: 'rgb(147 197 253) !important',
                                    color: 'white !important',
                                },
                                borderRadius: '0.375rem',
                            }}
                        >
                            Navigate to store
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default NavigationModal
