import axios from 'axios'
import { refreshTokens } from './userAPI'
import useAuthStore from '../store/useAuthStore'

export const axiosInstance = axios.create({
    headers: {
        'Cache-Control': 'no-cache',
    },
    params: {
        excludeRoot: false,
    },
    withCredentials: true,
})

// Automatically add access token to all requests
axiosInstance.interceptors.request.use(
    async (apiConfig) => {
        if (!apiConfig.headers) return apiConfig
        const accessToken = useAuthStore.getState().access
        apiConfig.headers.Authorization = `Bearer ${accessToken}`
        return apiConfig
    },
    (error) => {
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    async function (error) {
        const originalRequest = error?.config

        // If the error is 401 and the request has not been retried yet, try to refresh the access token
        if (error.response?.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true
            try {
                const response = await refreshTokens(localStorage.getItem('refresh-token') || undefined)
                const access = response.data.token
                if (!access) {
                    useAuthStore.getState().setAccess(null)
                    return Promise.reject(error)
                }
                useAuthStore.getState().setAccess(access)
                return await axiosInstance(originalRequest)
            } catch (_) {
                return Promise.reject(error)
            }
        }
        return Promise.reject(error)
    }
)
