import { useCallback, useEffect, useState } from 'react'
import { HiOutlineHome } from 'react-icons/hi'
import { HiHome } from 'react-icons/hi'
import { Button, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Login from '../Auth/Form/Login'
import Register from '../Auth/Form/Register'
import { RxAvatar } from 'react-icons/rx'
import { Logout } from '@mui/icons-material'
import { RiUserLine } from 'react-icons/ri'
import { getCurrentUser, logout } from '../api/userAPI'
import { useSelector } from 'react-redux'
import useAuthStore from '../store/useAuthStore'
import { convertUidProject, getProjectByUid } from '../api/projectAPI'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

const PageHeader = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { uid } = useParams()
    const accessToken = useAuthStore((state) => state.access)
    const [user, setUser] = useState({})
    const [needMigration, setNeedMigration] = useState(0)

    function handleClick() {
        location.pathname === '/' ? window.location.reload(false) : navigate('/')
    }

    const requestLoginStatus = useSelector((state) => state.requestLoginStatus)

    useEffect(() => {
        console.log('requestLoginStatus', requestLoginStatus)
        if (requestLoginStatus) {
            onOpenLoginForm()
        }
    }, [requestLoginStatus])

    const [isOpenLogin, setIsOpenLogin] = useState(false)
    const onOpenLoginForm = () => {
        setIsOpenLogin(true)
    }
    const onCloseLoginForm = () => {
        setIsOpenLogin(false)
    }

    const [isOpenRegForm, setIsOpenRegForm] = useState(false)
    const onOpenRegForm = () => {
        setIsOpenRegForm(true)
    }

    const onCloseRegForm = () => {
        setIsOpenRegForm(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogOut = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('refresh-token')
        navigate('/')
        window.location.reload()
    }

    const popupMigration = useCallback(async () => {
        try {
            const res = await getProjectByUid(uid || localStorage.getItem('uid'))
            if (res && res.length > 0) {
                setNeedMigration(res.length)
            }
        } catch (error) {
            console.log('error while getting project by uid', error)
        }
    }, [uid])

    const migrateProjects = async () => {
        try {
            await convertUidProject(uid || localStorage.getItem('uid'), accessToken)
            // eslint-disable-next-line no-self-assign
            window.location.href = window.location.href
        } catch (error) {
            console.log('error while migrating projects', error)
        }
    }

    useEffect(() => {
        const getUser = async () => {
            if (accessToken) {
                await getCurrentUser(setUser)
                await popupMigration()
            }
        }
        getUser()
    }, [accessToken, popupMigration])

    return (
        <div className='flex bg-slate-100 py-2 bg-gradient-to-r from-[#005072] to-[#AEBD38] bg-[length:100%_3px] bg-no-repeat bg-bottom'>
            {createPortal(
                <div
                    className={classNames(
                        'h-screen w-screen fixed z-[10] top-0 left-0 bg-black/50 flex transition',
                        needMigration > 0 ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
                    )}
                >
                    <div className='bg-white max-w-[500px] rounded-xl p-6 m-auto'>
                        <p className='text-xl font-semibold'>Migrate projects to your account?</p>
                        <p className='text-[#333] mt-2'>
                            You are having a uid in the local browser, which contains {needMigration} project
                            {needMigration > 1 ? 's' : ''}. Do you want to convert them to your account's workspace?
                        </p>
                        <div className='mt-2 flex justify-end gap-2'>
                            <Button
                                onClick={() => setNeedMigration(0)}
                                className='!border-[2px] !border-black'
                                sx={{
                                    padding: '11px 10px',
                                    borderRadius: '12px',
                                    background: 'white',
                                    color: 'black',
                                }}
                            >
                                No
                            </Button>
                            <Button
                                onClick={migrateProjects}
                                className='!bg-[#005072] border-r border-white'
                                sx={{
                                    borderRight: '1px solid white !important',
                                    padding: '11px 10px',
                                    borderRadius: '12px',
                                    color: 'white',
                                }}
                            >
                                Yes, migrate them
                            </Button>
                        </div>
                    </div>
                </div>,
                document.body
            )}
            <div className='flex w-full mx-8 items-center'>
                <Tooltip title='Home' arrow placement='bottom'>
                    <div className={`w-14 transition-transform transform-gpu hover:scale-110 px-1`}>
                        {location.pathname === '/' ? (
                            <HiHome
                                size='2.3rem'
                                onClick={handleClick}
                                className='cursor-pointer m-auto hover:rgb(84, 168, 176)'
                                color='#1C6269'
                            />
                        ) : (
                            <HiOutlineHome
                                size='2.3rem'
                                onClick={handleClick}
                                className='cursor-pointer m-auto hover:rgb(84, 168, 176)'
                                color='#1C6269'
                            />
                        )}
                    </div>
                </Tooltip>

                <h1
                    className='text-3xl h-10 text-center font-bold m-auto cursor-pointer select-none
                    bg-gradient-to-r from-[#005072] to-[#AEBD38] bg-clip-text text-transparent'
                    onClick={handleClick}
                >
                    digital.auto widget studio
                </h1>

                <div className=''>
                    <a
                        onClick={() => {
                            window.open(`https://bestudio.digitalauto.tech/project/QDC3xxv1HJlg/index.html`, '_blank')
                        }}
                        className='flex items-center text-[#005072] font-bold text-lg underline cursor-pointer transition-transform transform-gpu hover:scale-110'
                    >
                        Documentations
                    </a>
                </div>

                <div className='h-full bg-[#005072] mx-3 w-0.5'></div>

                {!accessToken && (
                    <button
                        onClick={onOpenLoginForm}
                        className='text-[#1C6269] font-bold text-lg underline transition-transform transform-gpu hover:scale-110'
                    >
                        Login
                    </button>
                )}

                {accessToken && (
                    <button className='text-[#1C6269] font-bold text-lg underline transition-transform transform-gpu hover:scale-110'>
                        <RxAvatar size='2.2rem' onClick={handleClick1} />
                    </button>
                )}

                <div>
                    <Login
                        isOpenLogin={isOpenLogin}
                        onCloseLoginForm={onCloseLoginForm}
                        onOpenRegForm={onOpenRegForm}
                        onLoginSuccess={(accessToken) => {
                            onLoginSuccess(accessToken)
                        }}
                    />
                </div>
                <div>
                    <Register
                        onCloseRegForm={onCloseRegForm}
                        isOpenRegForm={isOpenRegForm}
                        onOpenLoginForm={onOpenLoginForm}
                    />
                </div>
            </div>

            <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <RiUserLine size='1.2rem' />
                    </ListItemIcon>
                    {user?.fullName}
                </MenuItem>
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout fontSize='small' />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    )
}

export default PageHeader
